.seo-main-heading{
    font-size:28px;
    font-family:'Roboto';
    margin-top:20px;
    margin-bottom:20px;
    color:#0cb4f1;
}

.seo-img{
    width:460px;
    height:363px;
   
}
.seo-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top:76px;
}

.seo-cards-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color:#1c1f18;
    margin-bottom:30px;
    width:80%;
    border-radius:8px;
    padding-bottom:30px;
    padding-top:30px;
}

.seo-card{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
   
   background-color: #0cb4f1;
   color:white;
}


.seo-content-card{
    width:460px;
    height:310px;
    padding: 20px;
    padding-top:5px;
    
    font-family: initial;
    font-size:16px;
}
.seo-card-content-head{
    font-size:25px;
    margin-bottom:20px;
    color:black;
}


@media screen and (max-width:986px){

    .seo-cards-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color:#1c1f18;
        margin-bottom:30px;
        width:90%;
        border-radius:8px;
        padding-bottom:30px;
        padding-top:30px;
    } 
    .seo-main-heading{
        font-size:18px;
        text-align: center;
    }
    
    .seo-img{
        width:96%;
        padding:10px;
        height:250px;
        
    }

 
    .seo-card{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
       width:96%;
    
       background-color: #0cb4f1;
       color:white;
    }
    .seo-content-card{
        width:96%;;
       overflow-y: auto;
       
    }
}

/* @media screen and (max-width:680px){
    .seo-card{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
       width:96%;
       min-height:70vh;
       background-color: #0cb4f1;
       color:white;
       padding:12px;
    }
} */

@media screen and (min-width:950px) and (max-width:1120px){
    .seo-cards-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color:#1c1f18;
        margin-bottom:30px;
        width:90%;
        border-radius:8px;
        padding-bottom:30px;
        padding-top:30px;
    } 
}