.bot-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border:1px solid lightblue;
    background-color:rgb(250, 111, 111);
    box-shadow: #1c1f18 0px 4px 5px;
    padding: 30px;
    border-radius:8px;
}


@media screen and (max-width:986px){
    .pos-card-container {
        display: flex;
        flex-direction: column;
        width: 90%;
        margin-bottom: 60px;
    }
    
    .bot-card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        border:1px solid lightblue;
        background-color:#0cb4f1;
        box-shadow: #1c1f18 0px 4px 5px;
        padding: 30px;
        border-radius:8px;
    }

    
}