

.end-to-bottom{
    background-color:transparent;
    
    position:fixed;
    bottom:0;
    right:0;
   
  }
  
  .chat-bot-width{
    width:100%;
    
  }