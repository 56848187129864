.social-media-container{
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: flex-end;
    height:100vh;
    background-color:transparent;
    
    position:fixed;
    
    bottom:0;
    right:0;
}

.social-card{
    background-color: #1c1f18;
    display: flex;
    flex-direction: column;
    align-items: center;
    width:60px;
    height:250px;
    margin-top: 20px;
    border:1px solid skyblue;
}

.social-icons{
    font-size:30px;
    margin: 10px;
    color:skyblue;
    border-radius:50px;
   
}

.line-social{
    border-top:1px solid skyblue;
    width:60px
}

@media screen and (max-width:986px){
    .social-media-container{

        display: none;
    }
}