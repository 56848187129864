.form-registraion-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 100px;
    align-items: center;
    width:80%;
    
   
}

.form-container{
    width:80%;
    margin-top: 5px;
    border-radius: 4px;
    margin-bottom:10px;
    padding: 28px;
    padding-top:20px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
    background-color:#f7f3f2;
    margin-left: auto;

}
.registration-heading{
    font-size:25px;
    margin-bottom:20px;
    text-align: center;
}

@media screen and (max-width:986px){
    .form-registraion-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 100px;
        align-items: center;
        width:95%;
        
       
    }
    
    .form-container{
        width:95%;
        margin-top: 5px;
        border-radius: 4px;
        margin-bottom:10px;
        padding: 28px;
        padding-top:20px;
        box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
        background-color:#f7f3f2;
        margin-left: auto;
    
    }
    .registration-heading{
        font-size:20px;
        margin-bottom:20px;
        text-align: center;
    }
}