.input-box1{
    height:35px;
    width:310px;
    border-radius:3px;
    color:black;
}

label{
    font-size:18px;
}

.logo-container{
    height:80px;
    padding:20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
   
}

.chat-container{
    width:450px;
    height:550px;
    background-color:#0cb4f1;
    border-radius:8px;
    margin-right:20px;
}

.send-message-container{
   margin-top:310px;
   border:1px solid black;
   border-radius: 3px;
   width:350px;
  
}
.text-container{
    background-color:lightgrey;
    border-radius:8px;
    margin-left:18px;
    margin-right:10px;
    color:#1c1f18;
    padding:10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.chat-send-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color:black;
    height:50vh;
}

.input-box1{
    width:380px;
    border-radius: 5px;
    border:none;
    outline:none;
    margin-top: 20px;
}

@media screen and (max-width:986px){
    .chat-container{
        width:80%;
        height:500px;
        margin-left: auto;
        margin-left: 30px;
        margin-right:0px;
    }
    .input-box1{
        width:80%;
        margin-top: 20px;
        border-radius: 5px;
    }
    .send-message-container{
        
        border:1px solid black;
        border-radius: 3px;
        width:80%;
       
     }
     .chat-container{
        width:90%;
        height:70vh;
        background-color:#0cb4f1;
        border-radius:8px;
        margin-right:20px;
    }
    
}