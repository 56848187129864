* {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}



.image-box {
    position: relative;
    margin: auto;
    overflow: hidden;
    width: 420px;
    margin-bottom: 25px;
    border-radius:4px;
   
}

.portfolio-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width:80%;
    margin-top:10px;
}
.image-box img {
    max-width: 100%;
    transition: all 0.3s;
    display: block;
    width: 100%;
    height: auto;
    transform: scale(1);
    
}

.image-box:hover img {
    transform: scale(1.1);
}

