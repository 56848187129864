.head-container {
  background-color: transparent;
  color: white;
  width: 50%;
  color: navy;
  height: 380px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 120px;
}

.main-heading {
  font-size: 21px;
  margin-bottom: 10px;
  text-align: center;
  line-height: 1.2;
  color: white;
}

.paragraph {
  margin-top: 15px;
  font-size: 16px;
  text-align: center;
  color: white;
}

.analysis-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  margin: 15px;
  margin-bottom: 30px;
  padding-bottom: 30px;
  margin-top: 30px;
}

.analysis-image {
  width: 510px;
  margin-right: 60px;
  height: 480px;
}

.acheive-goal-container {
  width: 550px;
  margin-left: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.impression-heading {
  font-size: 25px;

  color: aqua;
  margin-bottom: 20px;
}


.happy-para {
  margin-top: 0px;
  font-size: 12px;
  text-align: center;
  color: black;
  margin-left: 0px;
  padding-left: 0px;
  flex-shrink: 0;
}



.client-card {
  margin-right: 40px;

}

.track-reacord-head {
  font-size: 23px;
  align-self: self-start;
  margin-top: 25px;
  margin-bottom: 12px;
  color: rgb(245, 215, 83);
}


/* .impression-card {
       position: relative;
      width: 80%;
      height:600px;
      background-color: skyblue;
      border-radius: 10px;
      /* background: rgba(255, 255, 255, 0.1); */
/* border-top: 1px solid rgba(255, 255, 255, 0.5);
      backdrop-filter: blur(15px);
      box-shadow: 0 15px 25px rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction:column;
      justify-content: center;
      align-items: center;
      margin-left: 160px; */



.total-clients {
  margin-bottom: 0px;
  font-size: 20px;
  margin-top: 12px;
}


.bg-container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  align-items: center;

  background-image: url('https://thumbs.gfycat.com/AntiqueScalyHorsechestnutleafminer-size_restricted.gif');
  height: 60vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}


.service-card-heading {
  font-size: 22px
}


ection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgb(28, 31, 24);
  background: linear-gradient(90deg, rgba(28, 31, 24, 1) 36%, rgba(12, 180, 241, 1) 100%);

}

.responsive-container {
  width: 85%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about-us-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  width:95%;
}

.about-us-head {
  font-size: 28px;
  font-family: 'Roboto';
  font-weight: bold;
}

.about-us-description {
  font-size: 16px;
}

.about-us-card {

  width: 60%;
  margin-left:15px;
  margin-right: 25px;
}

.input-box {
  width: 280px;
  border-radius: 3px;
  background-color: transparent;
  border: 1px solid white;
  height: 32px;
}

.subscribe-btn {
  color: white;
  background-color: goldenrod;
  backdrop-filter: blur(15px);
  border-radius: 3px;
  outline: none;
  cursor: pointer;
  border-color: white;
  height: 32px;
  border: 1px solid white;
}

.quick-link-head {
  color: #0cb4f1;
  font-size: 28px;
  font-family: 'Roboto'
}

.quick-link-items {
  font-size: 15px;
  color: white;

}

.card-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px; 
  width:90%;
}

.quick-links {
  margin-right: 30px;
  width: 210px;
 
}

.contact-us {
  width: 250px;
  margin-right: 35px;
  margin-bottom: 20px;
}

.our-newslettter {
  width: 310px;

}

.folo-card {
  margin-bottom: 40px;
  margin-right: 30px;
}

.folo {
  font-family: 'Roboto';
  color: #0cb4f1;
  font-size: 28px;
  margin-left: 18px;
}


/* .follow-us-section {
    background-color: white;
  }
  .follow-us-section-heading {
    text-align: center;
    color: #183b56;
    font-family: "Roboto";
    font-size: 28px;
    font-weight: 700;
  }*/
.follows-uss {

  height: 186px;
  margin-left: 35px;

}

.icons-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid red;
  padding-left: 18px;
  border-radius: 4px;
  backdrop-filter: blur(45px);
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin-left: 25px;
}

.icons {
  color: white;
  font-size: 15px;
  background-color: rgb(228, 207, 91);
  width: 40px;
  height: 40px;
  border-radius: 50px;

  padding: 10px;
  margin-right: 20px;
  cursor: pointer;

}

.footer-line {
  border: 1px solid white;
  width: 80%;
  margin-bottom: 10px;
}

.copy-right {
  font-size: 18px;
  color: white;
  margin-bottom: 15px;
}


@media screen and (max-width:986px) {
  .analysis-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
    margin: 15px;
    margin-bottom: 30px;
    padding-bottom: 30px;
    margin-top: 30px;
    width: 90%;
  }

  .about-us-head {
    font-size: 25px;
    font-family: 'Courier New';
  }

  .input-container {
    align-self: flex-start;
    margin-left:60px;
  }

  .about-us-description {
    font-size: 15px;
  }

  .about-us-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }

  .card-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }

  .bg-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center;
    margin: 0;
    background-image: url('https://thumbs.gfycat.com/AntiqueScalyHorsechestnutleafminer-size_restricted.gif');
    height: 400px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .head-container {
    background-color: transparent;
    color: white;
    width: 90%;
    color: navy;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 120px;
  }

  .analysis-image {
    width: 90%;

    height: 380px;
  }

  .acheive-goal-container {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 0px;
  }

  .about-us-card {
    padding: 15px;
    width: 80%;
    margin-left: 10px;
  }

  .input-box {
    width: 200px;
    border-radius: 3px;
    background-color: transparent;
    border: 1px solid white;
    height: 32px;
    margin-right: auto;
  }

  .quick-links {
    margin-right: 10px;
    width: 80%;

  }

  .subscribe-btn {
    border: 1px solid white;
    background-color: goldenrod;
  }

  .contact-us {
    width: 80%;
    margin-right: 10px;

  }

  .folo-card {
    margin-left: 22px;
    align-self: self-start;
    border-color: white;
   margin-right: 0;
  }

  .our-newslettter {
    width: 80%;
    margin-top: 0px
  }

  .icons-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid white;
    padding-left: 18px;
    border-radius: 4px;
    backdrop-filter: blur(45px);
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.1);
    padding: 10px;
   
  }

  .responsive-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

}



.app-container {
  width: 100%;
}




@media screen and (min-width:986px) and (max-width:1130px) {



  .client-card {
    display: flex;
    flex-direction: row;
  }
}

@media screen and (min-width:660px) and (max-width:1120px) {

  .analysis-image {
    width: 40%;
    margin-left: 20px;
  }



  .client-card {
    width: 80%;
  }


  .analysis-container {
    width: 100%;

  }

  .acheive-goal-container {
    width: 100%;
  }

  .responsive-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }

  .footer-section {
    width: 100%;
  }

}