.android-app-dev-container{
    background-image:url('https://img.freepik.com/free-vector/gradient-ui-ux-background_23-2149065783.jpg?w=996&t=st=1681807867~exp=1681808467~hmac=64efb4b7889fee8593b047d8c0acebbc23c8f5aa495f06284a6a6a69cf9da25f');
    background-size: cover;
    min-height:120vh;
    overflow-y: auto;
    background-position:center;
    width:100%;
    margin-top:0px;
}


.android-card{
    border-radius: 8px;
    background-color:transparent;
    width:860px;
    height:440px;
   
    /* border:1px solid red; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:flex-end;
    
    box-shadow: rgb(170, 40, 40) 0px 4px 5px;
   
    color:white;
    margin: 30px;
  
    padding: 25px;
    
}

.card2{
    
    box-shadow: rgb(49, 243, 123) 0px 4px 5px;
}

.and-card3{
    box-shadow: #e2a738 0px 4px 5px;
   margin-bottom: 20px;
}

.our-android-services{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 76px;
    margin-right:30px;
    height:100vh;
}
.android-app-mobile-img1{
    width:460px;
    height:350px;
    border-radius:4px;
}

.android-app-mobile-img{
    width:460px;
    height:350px;
    border-radius:4px;
}

.android-app-description{
    margin-top: 18px;
    margin-left: 30px;
    width:470px;
    font-size:18px;
    font-family:initial;
    font-weight:400;
    overflow-y: auto;
}


@media screen and (max-width:986px){
    .android-card{
      
        width:90%;
        height:440px;
        /* border:1px solid red; */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content:flex-end;
        
        box-shadow: rgb(170, 40, 40) 0px 4px 5px;
       
        color:white;
      
       margin-bottom: 20px;
        padding: 25px;
        
    }
    .android-app-mobile-img1{
        width:90%;
        height:180px;
        border-radius:4px;
    }
    
    .android-app-mobile-img{
        width:90%;
        height:180px;
        border-radius:4px;
    }
    .android-app-dev-container{
      
        background-color:#1c1f18;
        padding-top: 50px;
        background-size:cover;
        background-image:none;
    }
    .android-app-description{
        margin-top: 18px;
        margin-left: 30px;
        width:100%;
        font-size:18px;
        font-family:initial;
        font-weight:400;
        color:#0cb4f1;
    }
}


    

