.webdesign-img{
    width:100%;
    height:45vh;
    margin-top:76px;
    image-resolution:initial;
    
   
}

.approach-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   
}

.work-process-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:90%;
}

.our-work-head{
    font-size:25px;
    font-family:'Roboto';
    font-weight: bold;
    color:navy;
    margin-top: 25px;
}

.webdesign-card{
    margin-top: 15px;
    border:1px solid navy;
    background-color: #1c1f18;
    width: 80%;
    height:50vh;
    padding:20px;
    box-shadow: rgb(167, 31, 31) 0px 4px 5px;
    border-radius:8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom:30px;
}

.web-design-card-img{
    width:350px;
    height:260px;
   
    border-radius: 3px;
}
.identiy-description{
    font-size: 15px;
    font-family: Tahoma;
}
.web-design-description-card{
    display: flex;
    flex-direction: column;
    color:white;
    width:680px;
    overflow-y: auto;
}

.our-resource-card-heading{
    font-size:25px;
  
}


@media screen and (max-width:986px){

    .our-resource-card-heading{
        font-size:20px;
      
    }
    
.webdesign-card{
    margin-top: 15px;
    border:1px solid navy;
    background-color: #1c1f18;
    width: 100%;
    min-height:70vh;
    padding:20px;
    box-shadow: rgb(167, 31, 31) 0px 4px 5px;
    border-radius:8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom:30px;
}

.web-design-card-img{
    width:80%;
    height:210px;
   
    border-radius: 3px;
}

.identiy-description{
    font-size: 15px;
    font-family: Tahoma;
}
.web-design-description-card{
    display: flex;
    flex-direction: column;
    color:white;
    width:90%;
}
}