.about-us-container{
   
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:100%;
}
body{
    overflow-x: hidden;
}
.about-bg-img{
    background-image:url('https://img.freepik.com/premium-photo/words-with-about-us-web-concept-idea_352439-2487.jpg?w=1060');
    width:100%;
    height:45vh;
    background-size: cover;
    padding-bottom:35px;
    background-position:center;
    opacity:0.8;
}

.about-heading{
    font-size:25px;
    
}

.about-dscription{
    width:80%;
}

.about-us-card1{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width:90%;
}

.about-card{
    width:50%;
   
   
}
.quick-link-heads{
    color:white;
    font-size:25px;
    margin-bottom: 20px;
    font-family:"Roboto";
}

.office-image{
    width:46%;
    margin-left:20px;
    margin-top:20px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
    border-radius:3px;
}

.guid-lines-cards-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    width:80%;
}

.guid-line-card{
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
    width:45%;
    height:480px;
    border:1px solid gray;
    padding:8px;
    border-radius:5px;
    /* margin-left: auto; */
    margin-bottom: 25px;
    margin-right: 25px;

}

.guid-line-head{
    padding:8px;
    color:white;
    background-color: #0cb4f1;
    border-bottom-right-radius:4px;
    border-bottom-left-radius:4px;
    height:65px;
    font-size:20px;
    margin-bottom:22px;
}

.guid-line-description{
    font-family:initial;
    margin-bottom:15px;
}

.digital-platform-head{
    font-size:25px;
    text-decoration: underline;
    font-family: monospace;
    color: #0cb4f1;
    align-self: self-start;
    margin-left:138px;

}

.digital-platform-card{
    margin-top: 25px;
    width:45%;
    height:440px;
    border:1px solid lightgray;
    padding:10px;
    margin-bottom:25px;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
    margin-right: 20px;
}

.digital-heading{
    padding:8px;
    color:#1c1f18;
    background-color: rgb(228, 207, 91);
    border-bottom-right-radius:4px;
    border-bottom-left-radius:4px;
    height:65px;
    font-size:20px;
    margin-bottom:22px;
}

.digital-description{
   
    margin-bottom:20px;
}

.digital-sub-description{
    font-family:initial;
    margin-bottom:15px;
}

.digital-platform-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    width:80%;
    margin-bottom:30px;
}

.enquiry-card{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border:1px solid lightcyan;
    padding:25px;
    width:80%;
    margin-right:20px;
    margin-bottom:20px;
    border-radius:8px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
    background-color: #0cb4f1;
}


.enquiry-head{
    font-size:20px;
   
}

.all-section-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width:80%;
    
}
.about-us-1{
    width:80%;
    margin-right: 35px;
    margin-top: 50px;
}
.quick-links-1{
    width:80%;
    margin-right: 10px;
    margin-bottom: 45px;
  
}

.quick-link-items1{
    font-size:18px;
   
}

.contact-us1{
    width:80%;
    margin-right:35px;
    margin-bottom: 45px;
}
.icon-cont{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.contact-items{
    margin-bottom:0px;
    font-size:13px;
    color:white;
    margin-bottom:5px;
}

.footer-line1{
    border-top:1px solid white;
    margin-top: 30px;
    width:85%;
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    margin-left:20px;
  }
    

@media screen and (max-width:986px){
    .about-us-card1{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width:80%;
    }
    .about-card{
        width:90%;
      
       
    }
    .office-image{
        width:80%;
        margin-right:30px;
        margin-top:20px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
        border-radius:3px;
        
    margin-bottom: 10px;
    }
    .guid-lines-cards-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        
        width:80%;
    }.guid-line-card{
        box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
        width:80%;
       overflow-y:auto;
        border:1px solid gray;
        padding:8px;
        border-radius:5px;
        /* margin-left: auto; */
        margin-bottom: 25px;
        margin-right: 25px;
    
    }
    .guid-line-head{
        font-size:18px;
        height:55px;
    }
    .digital-platform-card{
        margin-top: 25px;
        width:100%;
        overflow-y: auto;
        height:450px;
        border:1px solid lightgray;
        padding:10px;
        margin-bottom:25px;
        border-radius: 3px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
        margin-right: 20px;
    }
    .digital-platform-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        width:80%;
        margin-bottom:30px;
    }
    .digital-platform-head{
        font-size:18px;
        text-decoration: underline;
        font-family: monospace;
        color: #0cb4f1;
        text-align: center;
      
        
        margin-top: 20px;
    }

    .enquiry-card{
        width:80%;
    }

    .all-section-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:80%;
   
    
}
.quick-link-heads{
    color:#0cb4f1;
}.quick-links-1{
    width:80%;
    margin-right: 20px;
    margin-bottom: 45px;
}

.quick-link-items1{
    font-size:15px;
    margin-bottom:8px;
}

.contact-us1{
    width:80%;
 
    margin-bottom: 45px;
}.footer-line1{
    border-top:1px solid white;
    margin-top: 30px;
    width:85%;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    margin-left:20px;
  }
   
    .copy-right{
        font-size:16px;
    }

}

@media screen and (min-width:370px) and (max-width:650px) {
    .guid-line-card{
            width:100%;
            overflow-y: auto;
        
    }
    .digital-platform-head{
        text-align:left;
    }
    .card3{
        height:50vh;
    }
    .enquiry-card{
        margin:0px;
         width:90%;
         padding:5px;
       
         margin-top: 30px;
         margin-right:20px;
         margin-bottom:20px;
         margin-left: 30px;
     }
     .enquiry-head{
         font-size:16px;
     }
     .button-enquiry{
         width:210px;
         height:35px;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left:20px;
     }


}


@media screen and (min-width:920px) and (max-width:1120px) {

 .digital-platform-container{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        width:90%;
        margin-bottom:30px;
    }

}