.whatsapp-card-heading{
    font-size:23px;
    color:rgb(0, 13, 128);
    margin-top:20px;
    margin-bottom:20px;
}
.whatsapp-main-heading{
    color:black;
}

.whatspp-description{
    color:white;
    width: 580px;
    margin-right: 40px;
    line-height: 2.2;
    font-size:20px;
}

.whatsapp-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border:1px solid lightblue;
    background-color:#56e4ad;
    box-shadow: #1c1f18 0px 4px 5px;
    padding: 30px;
    border-radius:8px;
    margin-top:25px;
}


@media screen and (max-width:986px){
    .pos-card-container {
        display: flex;
        flex-direction: column;
        width: 90%;
        margin-bottom: 60px;
    }
    
    .whatsapp-card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        border:1px solid lightblue;
        background-color:#0cb4f1;
        box-shadow: #1c1f18 0px 4px 5px;
        padding: 30px;
        border-radius:8px;
    }

    .whatspp-description {
        width: 90%;
        margin-right: 40px;
        line-height: 2.2;
        color:white;
        font-size:20px;
        margin-top: 25px;
        margin-left: 20px;
    }
    .pos-design-card-img {
        width: 90%;
        border-radius: 8px;
        margin-right: 40px;
        margin-left: 40px;
    }
    
}