.contact-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contact-form-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin-top: 110px;
  
}

.get-touch-contact-container {
    display: flex;
    align-items: center;
}

.get-touch-card {
    width: 410px;
    padding: 25px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
}

.contact-us-card {
    width: 340px;
    height: 560px;
    display: flex;
    flex-direction: column;
    color: white;

    padding: 25px;
    border-radius: 8px;
}

.contact-type {
    font-size: 18px;
    font-family: initial;
}

.contact-us-heading {
    font-size: 28px;
    font-family: 'Roboto';
    margin-bottom: 20px;
    padding-top: 50px;
    color: rgb(199, 62, 62);
}

.follow-us-icons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid lightgray;
    background-color: lightgray;
    height: 260px;
    width: 410px;

    border-radius: 8px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
}

.follow-us-img {
    height: 160px;
    width: 210px;
    margin-right: 12px;
    margin-bottom: 0px;
    background-color: transparent;
}

.follow-social-icon {
    font-size: 40px;
    margin-right: 15px;
    color: rgb(46, 136, 255);
}

.follow-social-icon2 {
    font-size: 50px;
    margin-right: 15px;
    color: rgb(46, 136, 255);
}



.enquiry-card-contact {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid lightcyan;
    padding: 25px;
    width: 75%;
    margin-top: 30px;
    margin-right: 20px;
    margin-bottom: 20px;
    margin-left: 30px;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
    background-color: #0cb4f1;
}

@media screen and (max-width:986px) {
    .contact-form-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 90%;
        margin-top: 110px;
      
    }

    .enquiry-card-contact {
        margin: 0px;
        width: 80%;
        padding: 5px;

        margin-top: 30px;
        margin-right: 20px;
        margin-bottom: 20px;
        margin-left: 30px;
    }

    .enquiry-head {
        font-size: 16px;
    }

    .button-enquiry {
        width: 210px;
        height: 35px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .get-touch-card {
        width: 100%;
        padding: 25px;
        box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
    }

    .follow-us-icons {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 1px solid lightgray;
        background-color: lightgray;
        height: 260px;
        width: 90%;
      margin-top: 15px;
        border-radius: 8px;
        box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
    }
    .contact-us-card {
        width: 50%;
        height: 460px;
        display: flex;
        flex-direction: column;
        color: white;
    
        padding: 25px;
        border-radius: 8px;
    }
    


}