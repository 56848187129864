.head-container {
  background-color: transparent;
  color: white;
  width: 50%;
  color: navy;
  height: 380px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 120px;
}

.our-serv-head{
  text-align:left;
  margin-left:120px
}


.main-heading {
  font-size: 25px;
  margin-bottom: 30px;
  text-align: center;
  line-height: 1.5em;
  color: white;
}


.paragraph {
  margin-top: 15px;
  font-size: 16px;
  text-align: center;
  color: white;
}

.analysis-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
 
  margin-bottom: 30px;
  padding-bottom: 30px;
  margin-top: 30px;
}

.analysis-image {
  width: 510px;
  margin-right: 60px;
  height: 480px;

}

.acheive-goal-container {
  width: 80%;
  margin-left: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.impression-heading {
  font-size: 25px;

  color: aqua;
  margin-bottom: 20px;
}

.impression-card {
  border: 1px solid gray;
  padding: 15px;
  border-radius: 5px;
  margin-top: 45px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
}

.status-card-mobile{
  display: flex;
}



.status-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid lightblue;
  border-radius: 8px;
  padding-left: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  width:80%;
}

.client-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top:15px;

}

.track-reacord-head {
  font-size: 23px;
  align-self:center;
  text-align: center;
  margin-bottom: 12px;
  color: rgb(245, 215, 83);
  margin-left: 20px;
}


/* .impression-card {
     position: relative;
    width: 80%;
    height:600px;
    background-color: skyblue;
    border-radius: 10px;
    /* background: rgba(255, 255, 255, 0.1); */
/* border-top: 1px solid rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(15px);
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    margin-left: 160px; */

.image-contenet-container{
  display:flex;
  flex-direction:row;
  justify-content: center;
  align-items:center;
  margin-top: 20px;
  
}
.analyze-img{
  width:500px;
  height:350px;
  margin-right:20px;
}

.impression-status-container{
  display: flex;
  flex-direction:column;
  justify-content:center;
  align-items: center;
  width:40%;
}

.imp-content-card{
  border:1px solid black;
  border-radius:8px;
  padding:15px;
  margin-top: 15px;
}

.awards-card{
  margin-left: 15px;
  display: flex;
  align-items:center;
}
.align-card{
  margin-right: 20px;
}

.awards-container{
  display: flex;
  flex-direction: row;
  border:1px solid black;
  padding:10px;

  border-radius: 8px;
}

.total-clients {
  margin-bottom: 0px;
  font-size: 20px;

}

.happy-para {
  margin-top: 0px;
  font-size: 12px;

  color: black;
  margin-left: 0px;
  padding-left: 0px;
  flex-shrink: 0;
}


.bg-container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  align-items: center;

  background-image: url('https://thumbs.gfycat.com/AntiqueScalyHorsechestnutleafminer-size_restricted.gif');
  height: 50vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.our-services-card-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-left:0px;
  margin-right: 10px;
}

.service-card-heading {
  font-size: 20px
}



.our-service-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 25px;
  margin-top: 25px;
  height: 360px;
  width: 310PX;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 20px;
  padding-top: 35px;
  margin-bottom: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
}

.footer-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgb(28, 31, 24);
  background: linear-gradient(90deg, rgba(28, 31, 24, 1) 36%, rgba(12, 180, 241, 1) 100%);

}

.responsive-container {
  width: 85%;
}

.about-us-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.about-us-head {
  font-size: 28px;
  font-family: 'Courier New';
}

.about-us-description {
  font-size: 18px;
}

.about-us-card {
  padding: 15px;
  width: 760px;
  margin-right: 80px;
}



.subscribe-btn {
  color: white;
  background-color: transparent;
  backdrop-filter: blur(15px);
  border-radius: 3px;
  outline: none;
  cursor: pointer;
  border-color: white;
  height: 32px;
  border: 1px solid black;
}

.quick-link-head {
  color: #0cb4f1;
  font-size: 28px;
  font-family: 'Courier New'
}

.quick-link-items {
  font-size: 15px;
  color: white;

}



.quick-links {
  margin-right: 35px;
  width: 210px;
  margin-bottom: 30px;
}




.folo {
  font-family: 'Courier New';
  color: #0cb4f1;
  font-size: 28px;
  margin-left: 18px;
}


/* .follow-us-section {
  background-color: white;
}
.follow-us-section-heading {
  text-align: center;
  color: #183b56;
  font-family: "Roboto";
  font-size: 28px;
  font-weight: 700;
}*/
.follows-uss {

  height: 186px;
  margin-left: 35px;

}



.icons {
  color: white;
  font-size: 15px;
  background-color: rgb(228, 207, 91);
  width: 40px;
  height: 40px;
  border-radius: 50px;

  padding: 10px;
  margin-right: 20px;
  cursor: pointer;

}

.footer-line {
  border: 1px solid white;
  width: 80%;
  margin-bottom: 10px;
}

.copy-right {
  font-size: 18px;
  color: white;
  margin-bottom: 15px;
}


@media screen and (max-width:986px) {

  .our-serv-head{
    text-align:center;
    margin-left:0px
  }

  .analyze-img{
    width:70%;
    height:350px;
    margin-right:0px;
    margin-bottom: 20px;
  }

  .happy-para{
    width:80px;
  }

  .image-contenet-container{
      display: flex;
      flex-direction: column;
  }
  .impression-status-container{
    width:80%;
  }
  .align-card{
    margin-left: 20px;
  }
  .icon-award{
    margin-right:12px;
  }
  .awards-container{
    width:90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .analysis-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
    margin: 15px;
    margin-bottom: 30px;
    padding-bottom: 30px;
    margin-top: 30px;
    width: 90%;
  }
  .status-card-mobile{
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .about-us-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
  .our-service-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    margin-top: 25px;
    height: 360px;
    width:90%;
    border: 1px solid lightgray;
    border-radius: 5px;
    padding: 20px;
    padding-top: 35px;
    margin-bottom: 20px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  }

 



  .head-container {
    background-color: transparent;
    color: white;
    width: 90%;
    color: navy;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 120px;
  }

  .analysis-image {
    width: 80%;
margin-right: 10px;
    height: 380px;
  }

  .acheive-goal-container {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 0px;
  }

  .status-card {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid lightblue;
    border-radius: 8px;
    padding-left: 20px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  }

  .about-us-card {
    padding: 15px;
    width: 80%;
    margin-right: 80px;
  }

 

  .quick-links {
    margin-right: 10px;
    width: 80%;
    margin-bottom: 30px;
  }

  .subscribe-btn {
    border: 1px solid black;
  }


}






  .bg-container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    align-items: center;
    
    background-image: url('https://thumbs.gfycat.com/AntiqueScalyHorsechestnutleafminer-size_restricted.gif');
    height: 410px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .main-heading {
    font-size: 18px;
    margin-bottom: 10px;
    text-align: center;
    line-height: 0%;
    color: white;
  }
  .client-card3{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }







.app-container {
  width: 100%;
}


.icons-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid white;
  padding-left: 18px;
  border-radius: 4px;
  backdrop-filter: blur(45px);
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.1);
  padding: 10px;

}

@media screen and (min-width:660px) and (max-width:1120px) {

  .happy-para{
    width:45px;
  }


  .analysis-image {
    width: 60%;
    margin-right: 10px;
    height: 480px;
  
  }
  .awards-container{
    width:99%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }


  /* .analysis-image{
    width:40%;
    margin-left: 20px;
    margin-right: 10px;
  } */

  .status-card {
    width: 90%;
    display: flex;
    flex-direction: row;
    padding-right:15px;
    margin-right: 30px;
    
  }.client-card{
    width:80%;
  }
  .impression-card{
    width:70%;
    margin-left: 10px;
  }
  .analysis-container {
    width:100%;

  }
  .acheive-goal-container {
    width:100%;
    margin-left:10px;
  }
  .responsive-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }
  .footer-section{
    width:100%;
  }
  
}