.smm-container-bg {
    background: rgb(5, 31, 143);
    background: linear-gradient(90deg, rgba(5, 31, 143, 1) 30%, rgba(106, 247, 198, 1) 100%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px;
}

.smm-bg-img {
    width: 510px;
    height: 310px;
    border-radius: 8px;
    margin: 25px;
}

.smm-main-heading {
    font-size: 28px;
    color: white;
    text-align: center;
}

.smm-cards-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.smm-card {
    display: flex;
    margin-top: 25px;
    margin-bottom: 25px;
    align-items: center;
    box-shadow: rgba(106, 247, 198, 1)1c1f18 0px 4px 5px;
    width: 83%;
}

.smm-card-img {
    width: 780px;
    height: 470px;
    border-radius: 3px;
}

.smm-card-contet {
    font-family: initial;
    padding: 20px;
    width: 530px;
    height: 390px;
    border: 2px solid lightgray;
    overflow-y: auto;
}

.smm-card2-container {
    margin-bottom: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}

.smm-card2 {
    margin-top: 80px;
    height: 750px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
    border: 2px solid gray;
    margin-right: 20px;
    padding: 20px;
    background-color: #051f8f;
    color: white;
    border-radius: 8px;
}

.smm-card-heading {
    font-size: 22px;
    font-family: 'Roboto';
}

.smm-card2-img {
    width: 450px;
    height: 370px;
    border-radius: 5px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.smm-card3 {
    height: 680px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
    margin-left: 30px;
    margin-bottom: 80px;
    border: 2px solid gray;
    padding: 20px;
    background-color: #99eed0;
    border-radius: 8px;
}

@media screen and (max-width:986px) {

    .smm-cards-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width:90%;
    }
    
    .smm-container-bg {
        background: rgb(5, 31, 143);
        background: linear-gradient(90deg, rgba(5, 31, 143, 1) 30%, rgba(106, 247, 198, 1) 100%);
        display: flex;
        flex-direction: column;
        margin-top: 25px;       
    }

 

    .smm-card {
        display: flex;
        flex-direction: column;
        margin-top: 25px;
        margin-bottom: 25px;
        align-items: center;
        box-shadow: rgba(106, 247, 198, 1)1c1f18 0px 4px 5px;
        width: 95%;
        margin-left: 20px;
    }

    .smm-card-img {
        width: 95%;
        height: 270px;
        border-radius: 3px;
    }

    .smm-card2-container {
        margin-bottom: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        justify-content: center;
        align-items: center;
       width:90%;
    }

    .smm-card2 {
        margin-top: 80px;
        height: 750px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 98%;

    }

    .smm-card-heading {
        font-size: 22px;
        font-family: 'Roboto';
    }

    .smm-card2-img {
        width: 80%;
        height: 270px;
        border-radius: 5px;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .smm-card3 {
        margin-top: 20px;
        height: 680px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 98%;
        margin-left: 30px;
        margin-bottom: 80px;
        border: 2px solid gray;
        padding: 20px;
        background-color: #99eed0;
        border-radius: 8px;
    }.smm-bg-img {
        width: 100%;
        height: 210px;
        border-radius: 8px;
        margin: 25px;
    }
    
    .smm-main-heading {
        font-size: 20px;
        color: white;
        text-align: center;
    }
    .smm-card-contet {
        font-family: initial;
        padding: 20px;
        width: 95%;
        height:390px;
      
        border: 2px solid lightgray;
    }

}


@media screen and (min-width:400px) and (max-width:600px){
    .smm-card-contet {
        font-family: initial;
        padding: 20px;
        width: 95%;
        
 
        border: 2px solid lightgray;
    }
}

@media screen and (min-width:987px) and (max-width:1420px){
    .smm-card-contet {
        font-family: initial;
        padding: 20px;
        width: 95%;
       
       min-height:460px;
        border: 2px solid lightgray;
    }

    .smm-card{
       min-height:40vh;
    }
}