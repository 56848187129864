.sidebar-container{
    min-height:100vh;
    background-color:#1c1f18;
    box-shadow: 0px 4px  4px lightpink;
    margin: 5px;
    margin-bottom:10px;
    width:380px;
    margin-top: 76px;
    flex-shrink:0;
    margin-left:0px;
    position:fixed;
    top:0;
}

.list-item{
    font-size:18px;
    margin-left:10px ;
    margin-top:22px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
   justify-content: space-between;
   align-items: center;
    color:skyblue;
    margin-left:20px;
    
}

.border-line{
    border:1px solid #1c1f18;width:100%;
}

.list-item2{
    font-size:18px;
    margin-left:10px ;
    margin-top:22px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
   justify-content: space-between;
   align-items: center;
    color:skyblue;
    width:290px;
    margin-left:20px;
}

.service-icon-align{
     display: flex;
     flex-direction: row;
    justify-content: space-between;
    align-items: center;
}


.ul1{
    margin-left:0px;
    padding-left:0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
}

.drop-items{
    margin-left:8px;
    padding-left:0px;
    margin-top:20px;
    margin-bottom:20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color:white;
}
.drop-container{
    border:1px solid lightcyan;
    border-radius: 5px;
    width:90%;
    margin-left:0px;
    padding-left:0px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
    margin-right: 15px;
    margin-left: 15px;
}


.list-item-container{
    border:1px solid lightskyblue;
    margin-right: 22px;
    margin-top: 10px;
    border-radius: 3px;
}

.icon{
    margin-top: 10px;
    margin-left: 10px;
}

.drop-icon{
 
    margin-right:20px;
}

.sidebar-bg-container{
    width:80%;
    background-color:#1c1f18;
}

@media screen and (max-width:986px){
    .side-bar{
        display: block;
    }
}

@media screen and (min-width:986px){
    .side-bar{
        display: none;
    }
    .sidebar-container{
        display: none;
    }
}