.pos-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top:120px;
}

.pos-card-container {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-bottom: 60px;
}

.pos-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border:1px solid lightblue;
    background-color:#0cb4f1;
    box-shadow: #1c1f18 0px 4px 5px;
    padding: 30px;
    border-radius:8px;
}

.pos-design-card-img {
    width: 50vh;
    border-radius: 8px;
    margin-right: 40px;
    margin-left: 40px;
}

.pos-description {
    width: 480px;
    margin-right: 40px;
    line-height: 3.2;
    color:white;
    font-size:20px;
}

@media screen and (max-width:986px){
    .pos-card-container {
        display: flex;
        flex-direction: column;
        width: 90%;
        margin-bottom: 60px;
    }
    
    .pos-card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        border:1px solid lightblue;
        background-color:#0cb4f1;
        box-shadow: #1c1f18 0px 4px 5px;
        padding: 30px;
        border-radius:8px;
    }

    .pos-description {
        width: 90%;
        margin-right: 40px;
        line-height: 2.2;
        color:white;
        font-size:20px;
        margin-top: 25px;
        margin-left: 20px;
    }
    .pos-design-card-img {
        width: 90%;
        border-radius: 8px;
        margin-right: 40px;
        margin-left: 40px;
    }
    
}