.emedha-logo{
    width:280px;
    height:65px;
    background-color: transparent;
    border-radius: 8px;
    margin-left: 0px;
}
.nav-items{
    margin-left: auto;
    color:white;
    display: flex;
    align-items: center;
    color:rgb(46, 136, 255);
}

.dropdown{
    color:rgb(46, 136, 255) ;
    margin-right: 9px;
}

.dropdown-items{
    color:lightskyblue;
    margin-top: 8px;
}

.large-screen{
    color:'white';
  
}

.navbar1{
    box-shadow: 1px 1px 0px 0px lightskyblue;
    background-color:lightgray;
    height:76px;
}

@media screen and (min-width:986px){

.small-md-screen{
    display: none;
}

}

@media screen and (max-width:986px){
    .large-screen{
        display:none;
}
    .small-md-screen{
    display: block;
   
}
.emedha-logo{
    width:210px;
    height:50px;
    background-color: transparent;
    border-radius: 8px;
    margin-left: 0px;
}
.navbar1{
    box-shadow: 1px 1px 0px 0px lightskyblue;
    background-color:lightgray;
    width:100%;
}
}