.approach-img{
    width:100%;
    height:25vh;
    margin-top:76px;
    
}

.approach-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   
}

.work-process-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:80%;
}
.our-work-head{
    font-size:25px;
    font-family:'Roboto';
    font-weight: bold;
    color:navy;
    margin-top: 25px;
}

.approach-card{
    margin-top: 15px;
    border:1px solid navy;
    background-color: #1c1f18;
    width: 80%;
    height:40vh;
    padding:20px;
    box-shadow: #0cb4f1 0px 4px 5px;
    border-radius:8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom:30px;
}

.identify-img{
    width:40%;
    height:250px;
   
    border-radius: 3px;
}
.identiy-description{
    font-size: 15px;
    font-family: Tahoma;
}
.description-card{
    width:40%;
    color:white;
    margin-left:15px;
}

.our-resource-card-heading{
    font-size:25px;
    color:#0cb4f1;
}

@media screen and (max-width:986px){
    .identify-img{
        width:100%;
        height:180px;
    }.approach-card{
        margin-top: 15px;
      
        width: 90%;
        height:450px;
        overflow-y:auto;
        padding:20px;
        box-shadow: #0cb4f1 0px 4px 5px;
        border-radius:8px;
        display: flex;
        flex-direction: column;
    
        align-items: center;
        margin-bottom:30px;
    }
    .description-card{
        width:90%;
        color:white;
        margin-left:15px;
    }
}